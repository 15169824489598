import React, { useState, useEffect, useContext } from "react"
import { graphql, StaticQuery } from "gatsby"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import { RichText } from "prismic-reactjs"
import StoreContext from "../context/StoreContext"
import ShopStepProducts from "./ShopStepProducts"
import ShopStepSizes from "./ShopStepSizes"
import ShopStepPoles from "./ShopStepPoles"
import ShopStepAddOns from "./ShopStepAddOns"
import Card from "./Card"
import OrderSummary from "./OrderSummary"
import StepsContent from "./StepsContent"
import ProgressBar from "./ProgressBar"
import CustomizerHeader from "./CustomizerHeader"
import StepsHeader from "./StepsHeader"
import StepsNav from "./StepsNav"
import {
  CSSTransition,
  TransitionGroup,
  SwitchTransition,
} from "react-transition-group"
import Img from "./CustomImg"
import ContentZoom from "react-content-zoom"
import { add } from "lodash"
import getTranslations from "../translations"

const shopQuery = graphql`
  query {
    prismic {
      allShops {
        edges {
          node {
            _meta {
              lang
            }
            collections_title
            sizes_title
            addons_title
            poles_title
            sizes_guidance {
              sizes_guidance_description
              sizes_guidance_title
            }
            poles_guidance {
              poles_guidance_text
              poles_guidance_title
            }
            sizes_description
            contact_description
            poles {
              pole
            }
            min_width
            min_length
            max_width
            max_length
            default_sizes {
              default_size_length
              default_size_title
              default_size_width
            }
            collections_guidance {
              collections_guidance_description
              collections_guidance_title
            }
            addons {
              addon_product
            }
          }
        }
      }
    }
  }
`

const Customizer = props => {
  const { lang } = props
  const t = getTranslations(lang)
  const context = useContext(StoreContext)

  const { adding, addLineItemsToCart } = context

  const [stepIndex, setStepIndex] = useState(0)
  const [productIndex, setProductIndex] = useState(0)
  const [productObj, setProductObj] = useState({})
  const [productObjVariant, setProductObjVariant] = useState(null)
  const [defaultSizeIndex, setDefaultSizeIndex] = useState(0)
  const [width, setWidth] = useState(null)
  const [length, setLength] = useState(null)
  const [poleIndex, setPoleIndex] = useState(0)
  const [poleObj, setPoleObj] = useState({})
  const [poleObjVariant, setPoleObjVariant] = useState({})
  const [quantity, setQuantity] = useState(1)
  const [combinedImgName, setCombinedImgName] = useState([])
  const [secondImage, setSecondImage] = useState()
  const [maxLength, setMaxLength] = useState(null)
  const [minLength, setMinLength] = useState(null)
  const [maxWidth, setMaxWidth] = useState(null)
  const [minWidth, setMinWidth] = useState(null)
  const [productImage, setProductImage] = useState(null)
  const [productImageClose, setProductImageClose] = useState(null)

  const [addOnsObj, setAddOnsObj] = useState(null)
  const [addOnsVariants, setAddOnsVariants] = useState([])
  const [selectedAddOns, setSelectedAddOns] = useState([])
  const [showCustomizer, setShowCustomizer] = useState(true)

  const handleAddToCart = () => {
    let lineItemsToAdd = [
      {
        variantId: btoa(productObjVariant.admin_graphql_api_id),
        quantity: quantity,
        customAttributes: [
          { key: "Størrelse", value: `B:${width}CM × H:${length}CM` },
        ],
      },
      {
        variantId: btoa(poleObjVariant.admin_graphql_api_id),
        quantity: quantity,
        customAttributes: [
          { key: "Størrelse", value: `B:${width}CM × H:${length}CM` },
        ],
      },
    ]

    if (selectedAddOns) {
      selectedAddOns.forEach(addOn => {
        lineItemsToAdd.push({
          variantId: btoa(addOn.admin_graphql_api_id),
          quantity: quantity,
          customAttributes: [],
        })
      })
    }

    addLineItemsToCart(lineItemsToAdd)
  }

  useEffect(() => {
    let variationWidth, variationLength
    if (width <= maxWidth && length <= maxLength && productObj.variants) {
      variationWidth = parseInt(
        productObj.variants.filter(variant => variant?.option2 >= width)[0]
          ?.option2
      )
      variationLength = parseInt(
        productObj.variants.filter(variant => variant?.option1 >= length)[0]
          ?.option1
      )

      if (
        !Number.isInteger(variationWidth) &&
        !Number.isInteger(variationLength)
      ) {
        setShowCustomizer(false)
      }

      productObj.variants.map((el, i) => {
        if (el.option2 == variationWidth && el.option1 == variationLength) {
          setProductObjVariant(el)
        }
      })

      if (poleObj && get(poleObj, "variants")) {
        if (get(poleObj, "variants").length > 1) {
          setPoleObjVariant(
            poleObj.variants.filter(variant => variant.option1 >= width)[0]
          )
        } else {
          setPoleObjVariant(get(poleObj, "variants[0]"))
        }
      }
    }
    if (addOnsObj) {
      setAddOnsVariants([])
      addOnsObj.forEach((addOnObj, i) => {
        const addOnTitle = get(addOnObj.addon_product, "title")
        const addOnDescription = get(addOnObj.addon_product, "body_html")
        const addOnVariants = get(addOnObj.addon_product, "variants")
        const addOnVariant = addOnVariants.filter(
          variant => variant.option1 >= width
        )[0]

        setAddOnsVariants(addOnsVariants => [
          ...addOnsVariants,
          {
            title: addOnTitle,
            description: addOnDescription,
            product: addOnVariant,
          },
        ])
      })
    }
  }, [width, length, productObj, poleObj, addOnsObj])

  useEffect(() => {
    if (poleObj && get(poleObj, "variants")) {
      if (get(poleObj, "variants").length > 1) {
        setPoleObjVariant(
          get(poleObj, "variants")
            .sort((a, b) => a.option1 - b.option1)
            .find(item => item.option1 >= width)
        )
      } else {
        setPoleObjVariant(get(poleObj, "variants[0]"))
      }
    }
  }, [poleObj])

  useEffect(() => {
    if (productObj.image) {
      setProductImageClose(productObj.image.src)
    }

    let curtainSKU = get(productObjVariant, "sku")
    let poleSKU = get(poleObjVariant, "sku")
    if (!poleSKU) {
      poleSKU = 520
    }

    setSecondImage(productObj?.image?.src)

    if (curtainSKU && poleSKU) {
      setCombinedImgName(`${curtainSKU}_${poleSKU}.jpg`)
    }
  }, [productObjVariant, poleObjVariant])

  const description = props.description.filter((el, i) => {
    return i === 0 ? el : null
  })

  return (
    <StaticQuery
      query={shopQuery}
      render={data => {
        const { products, title, heading } = props

        let shopData = data.prismic.allShops.edges.find(shop => {
          return shop.node._meta.lang === lang
        })

        if (shopData) {
          shopData = shopData.node
        }

        const collectionsTitle = get(shopData, "collections_title[0].text")
        const collectionsGuidance = get(shopData, "collections_guidance")

        const sizesTitle = get(shopData, "sizes_title[0].text")
        const sizesGuidance = get(shopData, "sizes_guidance")
        const defaultSizes = get(shopData, "default_sizes")
        const sizesDescription = get(shopData, "sizes_description")
        const contactDescription = get(shopData, "contact_description")

        const polesTitle = get(shopData, "poles_title[0].text")
        const poles = get(shopData, "poles")
        const polesGuidance = get(shopData, "poles_guidance")

        const addOnsTitle = get(shopData, "addons_title[0].text")

        const addOns = get(shopData, "addons")

        const steps = [
          {
            name: collectionsTitle,
            component: (
              <ShopStepProducts
                title={collectionsTitle}
                products={products}
                guidance={collectionsGuidance}
                productIndex={productIndex}
                setProductIndex={setProductIndex}
                productObj={productObj}
                setProductObj={setProductObj}
                setProductImage={setProductImage}
              />
            ),
          },
          {
            name: sizesTitle,
            component: (
              <ShopStepSizes
                title={sizesTitle}
                description={sizesDescription}
                contactDescription={contactDescription}
                guidance={sizesGuidance}
                defaultSizes={defaultSizes}
                maxLength={maxLength}
                minLength={minLength}
                maxWidth={maxWidth}
                minWidth={minWidth}
                defaultSizeIndex={defaultSizeIndex}
                setDefaultSizeIndex={setDefaultSizeIndex}
                width={width}
                setWidth={setWidth}
                length={length}
                setLength={setLength}
                lang={lang}
              />
            ),
          },
          {
            name: polesTitle,
            component: (
              <ShopStepPoles
                title={polesTitle}
                guidance={polesGuidance}
                products={poles}
                poleIndex={poleIndex}
                setPoleIndex={setPoleIndex}
                poleObj={poleObj}
                setPoleObj={setPoleObj}
                lang={lang}
              />
            ),
          },
          {
            name: addOnsTitle,
            component: (
              <ShopStepAddOns
                addOnsVariants={addOnsVariants}
                title={addOnsTitle}
                selectedAddOns={selectedAddOns}
                setSelectedAddOns={setSelectedAddOns}
                lang={lang}
              />
            ),
          },
        ]

        if (isEmpty(productObj)) {
          setProductObj(products[productIndex].shopify)
        }
        if (isEmpty(poleObj)) {
          setPoleObj(poles[poleIndex]?.pole)
        }
        if (isEmpty(addOnsObj)) {
          setAddOnsObj(addOns)
        }

        if (!width || !length) {
          setLength(parseInt(defaultSizes[0]?.default_size_length))
          setWidth(parseInt(defaultSizes[0]?.default_size_width))
          setMaxLength(get(shopData, "max_length"))
          setMinLength(get(shopData, "min_length"))
          setMaxWidth(get(shopData, "max_width"))
          setMinWidth(get(shopData, "min_width"))
        }

        return (
          <div className="shop-customizer">
            <CustomizerHeader title={title} heading={heading} />
            <div className="visuals">
              <ul>
                <li>
                  <SwitchTransition>
                    <CSSTransition
                      key={combinedImgName}
                      timeout={500}
                      classNames="product-image"
                      addEndListener={(node, done) =>
                        node.addEventListener("transitionend", done, false)
                      }
                    >
                      <ContentZoom
                        zoomPercent={350}
                        largeImageUrl={`/customizer/${combinedImgName}`}
                        imageUrl={`/customizer/${combinedImgName}`}
                      />
                      {/* <Img className="product-image" src={`/customizer/${combinedImgName}`} /> */}
                    </CSSTransition>
                  </SwitchTransition>
                  {secondImage && (
                    <div>
                      <div className="customizer-close-up-loading">
                        Henter billede
                      </div>
                      <img src={secondImage} />
                    </div>
                  )}
                  {productImage && (
                    <div>
                      <div className="customizer-close-up-loading">
                        Henter billede
                      </div>
                      <img src={productImage} />
                    </div>
                  )}
                </li>
              </ul>
            </div>
            <div className="customizer">
              <CustomizerHeader title={title} heading={heading} />
              {showCustomizer && (
                <>
                  <Card>
                    <ProgressBar stepIndex={stepIndex} />
                    <StepsHeader steps={steps} stepIndex={stepIndex} />
                    <StepsContent steps={steps} stepIndex={stepIndex} />
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: 1 }}>
                        <OrderSummary
                          length={length}
                          width={width}
                          productObj={productObj}
                          productObjVariant={productObjVariant}
                          poleObj={poleObj}
                          stepIndex={stepIndex}
                          setStepIndex={setStepIndex}
                          quantity={quantity}
                          setQuantity={setQuantity}
                          poleObjVariant={poleObjVariant}
                          selectedAddOns={selectedAddOns}
                        />
                      </div>

                      <div style={{ flex: 1 }}>
                        <StepsNav
                          steps={steps}
                          stepIndex={stepIndex}
                          setStepIndex={setStepIndex}
                          handleAddToCart={handleAddToCart}
                          adding={adding}
                          lang={lang}
                        />
                      </div>
                    </div>

                    <div style={{ display: "none" }}></div>
                  </Card>
                  <div className="fs--s" style={{ marginTop: 20 }}>
                    {t.customizer.note}{" "}
                    <a href="mailto:hi@anddrape.com">hi@anddrape.com</a>
                  </div>
                </>
              )}
              {!showCustomizer && <div>{RichText.render(description)}</div>}
            </div>
          </div>
        )
      }}
    />
  )
}

Customizer.query = shopQuery

export default Customizer
